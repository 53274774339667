@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.list-s {
  padding: 2px;
  cursor: pointer;
}

.list-s:hover {
  background-color: #6c757d !important;
}

h3 {
  font-weight: bolder !important;
}

html,
body {
  height: 100%;
}

.font-weight-bold {
  font-weight: 600 !important;
}

$body-bg: #ffff;
$primary-blue: #170052;
$secondary-blue: #4e3882;
$primary-ember: #ebad1f;
$primary-pink: #e33960;

.text-ember {
  color: $primary-ember;
}

.text-white {
  color: #fff;
}

.text-blue {
  color: $primary-blue;
}

.text-pink {
  color: $primary-pink;
}

.text-light-blue {
  color: $secondary-blue;
}

.text-disabled {
  color: rgba(171, 171, 171, 0.867);
}

$theme-colors: (
  primary: #170052,
  danger: #e33960,
);

.form-check-input {
  background-color: #fff !important;
  border: 1.5px solid #e33960 !important;
  padding: 3px !important;
}

.form-check-input:checked[type="checkbox"] {
  background-color: #170052 !important;
}

.bg-grey {
  background: #f4f2f8 !important;
}

.btn-primary {
  background-color: #e33960 !important;
  border: #e33960 !important;
  color: white !important;
  font-weight: 500 !important;
  padding: 5px;
  border-radius: 5px;
}

.text-lighter-primary {
  color: #4e3882 !important;
}

body {
  font-family: "Montserrat" !important;
}

input.primary {
  padding: 10px;
  background: #f4f2f8;
  border: 1.5px solid #f4f2f8;
  border-radius: 5px;
}

input.primary-delivery-details {
  padding: 5px;
  background: #f4f2f8;
  border: 1.5px solid #f4f2f8;
  border-radius: 5px;
  font-size: 12px;
  height: 25px;
  min-height: 20px;
}

textarea.primary {
  padding: 10px;
  background: #f4f2f8;
  border: 1.5px solid #f4f2f8;
  border-radius: 5px;
}

input.primary:focus {
  outline: none !important;
  border: 1.5px solid #e33960 !important;
}

.primary-border:focus {
  outline: none !important;
  border: 1.5px solid #e33960 !important;
}

label.primary {
  color: #170052;
  font-size: 20px;
  font-weight: 600;
}

.w-30 {
  width: 30% !important;
}

.opacity {
  position: absolute;
  opacity: 0;
  width: 0;
  /* for internet explorer */
}

.visibility {
  visibility: hidden;
}

.nodisplay {
  display: none;
}

.bg-grey {
  background: #f4f2f8 !important;
}

.radio-label {
  padding: 10px;
  background: #f4f2f8;
  border: 1.5px solid #f4f2f8;
  border-radius: 5px;
}

select.primary {
  background: #f4f2f8;
  border: 1px solid #f4f2f8;
  border-radius: 5px;
  padding: 10px;
}

input[type="radio"] + label {
  font-weight: normal !important;
}

input[type="radio"]:checked + label {
  outline: none !important;
  border: 1.5px solid #e33960 !important;
}

input[type="radio"]:focus + label {
  border: 1px dotted #000 !important;
  outline: none !important;
  border: 1.5px solid #e33960 !important;
}

.email-container {
  transform: translateY(25%);
}

.btn-secondary {
  background-color: #ffff;
  border: 1px solid #170052 !important;
  color: #170052 !important;
  padding: 5px;
  border-radius: 5px;
}

li.disabled {
  color: #6c757d !important;
  pointer-events: none !important;
}

.disable {
  pointer-events: none;
}

.disable > label {
  color: #6c757d !important;
}

.side-nav-custom {
  padding: 10px;
  font-weight: bold;
  color: #170052;
  cursor: pointer;
}

.side-nav-custom.active {
  background-color: #f4f2f8;
}

.side-nav-custom:hover {
  background-color: #f4f2f8;
}

input[type="radio"]:checked + label.select-box {
  background-color: #fff !important;
  border: 2px solid #e33960 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

label.select-box {
  width: 100% !important;
  height: 250px !important;
  padding: 5px;
  background-color: #f4f2f8 !important;
}

.select-box > .icon {
  width: 110px;
  height: 110px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  border-radius: 100%;
}

.select-box > .title {
  text-align: center;
  font-weight: bold;
  color: #170052;
  font-size: 20px;
  margin-top: 10px;
}

.select-box > .description {
  text-align: center;
  font-weight: normal;
  color: #170052;
  font-size: 18px;
}

input[type="radio"]:checked + label.select-box-parcel {
  background-color: #fff !important;
  border: 2px solid #e33960 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.select-box-parcel {
  min-width: 170px !important;
  min-height: 170px !important;
  margin: 10px;
  background-color: #f4f2f8 !important;
}

.select-box-parcel.disabled {
  background-color: #6c757d !important;
  pointer-events: none;
}

.select-box-parcel > .title {
  text-align: center;
  font-weight: bold;
  color: #170052;
  font-size: 20px;
  margin-top: 30%;
  max-width: 200px;
}

.select-box-parcel > .description {
  text-align: center;
  font-weight: normal;
  color: #170052;
  font-size: 18px;
  margin: auto;
  max-width: 200px;
}

.select-box-errand-list {
  min-width: 250px !important;
  min-height: 250px !important;
  margin: 10px;
  background-color: #f4f2f8 !important;
  padding: 10px;
}

.select-box-errand-list-icon {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  margin-right: auto;
}

.summary-title-text {
  color: #4e3882;
  font-size: 20px;
  font-weight: bold;
}

.date-primary-timepicker > div {
  padding: 5px;
  background: #f4f2f8;
  border: 1.5px solid #f4f2f8;
  border-radius: 5px;
  font-size: 12px;
}

.image-close {
  position: absolute;
  right: -10px;
  color: red;
  z-index: 99;
  background: white;
  top: -10px;
}

.react-datetime-picker__inputGroup__input {
  height: 100% !important;
}

.errand-fee > .summary-title-text {
  color: #4e3882;
  font-size: 42px;
  font-weight: bold;
}

ul.header-nav > li.active {
  font-weight: bold;
}

.float-btn {
  border-radius: 100%;
  height: 75px;
  width: 75px;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: $primary-pink;
}

.float-btn > * {
  cursor: pointer;
}

.contact-number > select {
  position: absolute;
  height: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23E33960' class='bi bi-calendar-fill' viewBox='0 0 16 16'%3E%3Cpath d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
  border-width: thin;
}

button.disabled {
  background-color: #6c757d !important;
  pointer-events: none;
}

.react-datetime-picker__clear-button__icon {
  stroke: $primary-pink !important;
}

.search {
  padding-left: 35px !important;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='16' fill='currentColor' class='bi bi-search' style='margin-left: 3px' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E")
    no-repeat left #f4f2f8 !important;
  background-size: 15px;
}

.address-book {
  margin: auto;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.address-box {
  margin: 10px;
  padding: 10px;
}

.address-container-box {
  cursor: pointer;
  margin: 10px;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.top-nav > ul > li {
  cursor: pointer;
}

.digit-group {
  input {
    width: 40px !important;
    height: 50px;
    background-color: #f4f2f8 !important;
    border: none;
    line-height: 50px;
    text-align: center;
    font-size: 24px;
    font-family: "Raleway", sans-serif;
    font-weight: 200;
    color: $primary-blue;
    margin: 0 2px;
  }
}

.add-to-contact-btn {
  font-size: 12px;
  width: 35%;
}

.custom-page-wrapper {
  padding-top: 100px;
  background-image: url("../src/assets/images/side-bar-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  min-height: calc(100vh - 65px);
  overflow-y: hidden;
}

.create-errand-nav {
  display: flex;
  flex-direction: row;
}

.display-sm-none {
  display: block;
}

.parcel-details-text-area {
  width: 75%;
}

/* For Desktop View */
@media screen and (min-width: 1024px) {
  .stepper-wrapper {
    display: none;
  }

  .side-image {
    height: 100%;
    width: auto;
  }

  .email-verification-button {
    width: 25%;
  }
}

.parcel-details-btn {
  text-align: "center";
  padding: "10px";
  width: 15% !important;
}

.summary-wrapper {
  width: 45%;
}

.errand-list-image {
  width: 100px;
  height: 100px;
}

.errand-list-img {
  width: 100px !important;
  height: 100px !important;
}

.final-errand-fee-box {
  width: 35%;
}

.info-modal-width {
  width: 70%;
}

.p-sm-1 {
  padding: 15px;
}
/* laptop */

@media screen and (max-device-height: 750px) {
  .stepper-wrapper {
    display: none;
  }

  .box-login-page > div {
    margin-left: 42% !important;
    width: 59% !important;
  }

  .select-code {
    width: 45%;
  }

  .email-verification-button {
    width: 75%;
  }
}

/* For Tablet View */
@media screen and (min-device-width: 700px) and (max-device-width: 1024px) {
  .stepper-wrapper {
    display: none;
  }

  .custom-page-wrapper {
    padding-top: 100px;
    background-image: none;
    margin: 2px !important;
  }

  .box-login-page > div {
    margin: 0 auto !important;
    width: 75% !important;
  }

  .email-verification-button {
    width: 50% !important;
  }

  .select-box > .title {
    font-size: 16px;
  }

  .select-box > .description {
    font-size: 12px;
  }

  .add-to-contact-btn {
    font-size: 12px;
    width: 75%;
  }

  .parcel-details-btn {
    width: 50% !important;
  }

  .summary-wrapper {
    width: 60%;
  }

  .errand-list-image {
    width: 70px !important;
    height: 70px !important;
  }

  .errand-list-img {
    width: 70px !important;
    height: 70px !important;
  }
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .custom-page-wrapper {
    padding-top: 100px;
    background-image: none;
    margin: 2px !important;
  }

  .box-login-page > div {
    margin: 0 !important;
    width: 100% !important;
  }

  .email-verification-button {
    width: 100%;
  }

  .add-to-contact-btn {
    font-size: 12px;
    width: 75%;
  }

  .parcel-details-btn {
    width: 50% !important;
  }

  .summary-wrapper {
    width: 60%;
  }

  .errand-list-image {
    width: 50px;
    height: 50px;
  }

  .errand-list-img {
    width: 50px !important;
    height: 50px !important;
  }

  .create-errand-nav {
    flex-direction: column;
  }

  .info-modal-width {
    width: 100%;
  }

  .step-nav {
    display: none;
  }

  .display-sm-none {
    display: none;
  }

  .select-box {
    display: flex;
    max-height: 120px;
  }

  .select-box > .icon {
    margin: 0px;
  }

  .parcel-details-text-area {
    width: 100%;
  }

  .map {
    display: none;
  }

  .summary-wrapper {
    width: 100%;
  }

  .final-errand-fee-box {
    width: 50%;
  }

  .p-sm-1 {
    padding: 8px;
  }
}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) {
  html {
    font-size: 13px;
  }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .custom-page-wrapper {
    padding-top: 100px;
    background-image: none;
    margin: 2px !important;
  }

  .box-login-page > div {
    margin: 0 !important;
    width: 100% !important;
  }

  .digit-group {
    input {
      width: 30px !important;
      height: 40px;
    }
  }

  .email-verification-button {
    width: 100%;
  }

  .add-to-contact-btn {
    font-size: 12px;
    width: 75%;
  }

  .summary-wrapper {
    width: 60%;
  }

  .errand-list-image {
    width: 50px;
    height: 50px;
  }

  .errand-list-img {
    width: 50px !important;
    height: 50px !important;
  }

  .create-errand-nav {
    flex-direction: column;
  }

  .step-nav {
    display: none;
  }

  .display-sm-none {
    display: none;
  }

  .info-modal-width {
    width: 100%;
  }

  .select-box {
    display: flex;
    max-height: 120px;
  }

  .select-box > .icon {
    margin: 0px;
  }

  .parcel-details-text-area {
    width: 100%;
  }

  .map {
    display: none;
  }

  .summary-wrapper {
    width: 100%;
  }

  .final-errand-fee-box {
    width: 50%;
  }

  .p-sm-1 {
    padding: 8px;
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .digit-group {
    input {
      width: 30px !important;
    }
  }

  .email-verification-button {
    width: 100%;
  }

  .errand-list-image {
    width: 50px;
    height: 50px;
  }

  .create-errand-nav {
    flex-direction: column;
  }

  .step-nav {
    display: none;
  }

  .display-sm-none {
    display: none;
  }

  .info-modal-width {
    width: 100%;
  }

  .select-box {
    display: flex;
    max-height: 120px;
  }

  .select-box > .icon {
    margin: 0px;
  }

  .parcel-details-text-area {
    width: 100%;
  }

  .map {
    display: none;
  }

  .summary-wrapper {
    width: 100%;
  }

  .final-errand-fee-box {
    width: 50%;
  }

  .p-sm-1 {
    padding: 8px;
  }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
  .gfg-div {
    width: 400px;
    height: 400px;
    background-color: darkgoldenrod;
    color: black;
  }

  .digit-group {
    input {
      width: 30px !important;
    }
  }

  .email-verification-button {
    width: 100%;
  }

  .errand-list-image {
    width: 50px;
    height: 50px;
  }

  .errand-list-img {
    width: 50px !important;
    height: 50px !important;
  }

  .create-errand-nav {
    flex-direction: column;
  }

  .step-nav {
    display: none;
  }

  .display-sm-none {
    display: none;
  }

  .info-modal-width {
    width: 100%;
  }

  .select-box {
    display: flex;
    max-height: 120px;
  }

  .select-box > .icon {
    margin: 0px;
  }

  .parcel-details-text-area {
    width: 100%;
  }

  .map {
    display: none;
  }

  .summary-wrapper {
    width: 100%;
  }

  .final-errand-fee-box {
    width: 50%;
  }

  .p-sm-1 {
    padding: 8px;
  }
}

.intercom-lightweight-app-launcher {
  left: 20px !important;
}

.gmnoprint {
  display: none !important;
}

.react-datetime-picker__clock {
  inset: auto auto 100% 0px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

@media (-webkit-device-pixel-ratio: 1.25) {
  * {
    zoom: 0.8;
  }
}

.gm-style .gm-style-iw-c {
  box-shadow: none;
}

.payment-date-input {
  border: 1px solid #ced4da;
  background-color: #fff;
  -webkit-transition: border-color 100ms ease;
  transition: border-color 100ms ease;
  font-family: Montserrat;
  height: 36px;
  -webkit-tap-highlight-color: transparent;
  line-height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  resize: none;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  color: #000;
  display: block;
  text-align: left;
  min-height: 36px;
  padding-left: 12px;
  padding-right: 30px;
  border-radius: 4px;
  white-space: nowrap;
}

.payment-date-input > .react-datetime-picker__wrapper {
  border: none;
}

.payment-date-label {
  display: inline-block;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #212529;
  word-break: break-word;
  cursor: default;
  -webkit-tap-highlight-color: transparent;
}
.mantine-SegmentedControl-controlActive{
  background: white;
}
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
